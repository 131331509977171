"use strict";
document.addEventListener('DOMContentLoaded', event => {
    /**
     * scroll resize thingy
     */
    setScrollbarWidth();
    let recordResize = null;
    window.addEventListener('resize', event => {
        if (!recordResize) {
            setTimeout(() => {
                setScrollbarWidth();
                recordResize = null;
            }, 100);
        }
    });
    function setScrollbarWidth() {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.documentElement.style.setProperty('--scrollbarWidth', scrollbarWidth + 'px');
    }
    /**
     * menu toggle button
     */
    const menuToggle = document.querySelector('.navigation__toggler');
    const menu = document.querySelector('.navigation__content');
    const body = document.body;
    if (menu !== null && menuToggle !== null) {
        menuToggle === null || menuToggle === void 0 ? void 0 : menuToggle.addEventListener('click', toggleMenu);
    }
    function toggleMenu() {
        body.classList.add('is-animating');
        if (menu.classList.contains('hidden')) {
            menu.classList.remove('hidden');
            setTimeout(() => {
                body.classList.add('menu-open');
            }, 1);
        }
        else {
            body.classList.remove('menu-open');
            setTimeout(() => {
                menu.classList.add('hidden');
            }, 350);
        }
        setTimeout(() => {
            body.classList.remove('is-animating');
        }, 350);
    }
});
